




















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissLab6ReflectionPart3',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        reflectionPart3: null,
      },
      questions: [
        {
          text: 'During these past three weeks, what have you learned about disseminating the results of your work to your peers? If your experiment is NOT successful, is THAT worth telling your colleagues about?',
          inputName: 'reflectionPart3',
        },
      ],
    };
  },
};
